export default {
    name: 'NotificationsSettings',
    data() {
        return {
            iframeHeight: 0,
        }
    },
    methods: {
        eventHandler(event) {
            let data = {};
            if (typeof event.data === 'object') {
                data = event.data;
            } else if (typeof event.data === 'string') {
                try {
                    data = JSON.parse(event.data);
                } catch (e) {
                    console.log('Parse event.data error', e);
                }
            }
            if (data.height) {
                this.iframeHeight = data.height;
            }
        },
    },
    mounted() {
        window.addEventListener('message', this.eventHandler, false)
    },
    beforeDestroy() {
        window.removeEventListener('message', this.eventHandler, false);
    },
    i18n: {
        messages: {
            uk: {
                'Керування сповіщеннями': 'Керування сповіщеннями',
                'Тут ви можете управляти вашими сповіщеннями від AUTO.RIA': 'Тут ви можете управляти вашими сповіщеннями від AUTO.RIA',
                'Щоб підписатись на важливу та актуальну для вас інформацію й вибрати зручний канал зв\'язку, просто увімкніть необхідну категорію сповіщень': 'Щоб підписатись на важливу та актуальну для вас інформацію й вибрати зручний канал зв\'язку, просто увімкніть необхідну категорію сповіщень',
            },
            ru: {
                'Керування сповіщеннями': 'Управление уведомлениями',
                'Тут ви можете управляти вашими сповіщеннями від AUTO.RIA': 'Здесь вы можете управлять своими уведомлениями от AUTO.RIA',
                'Щоб підписатись на важливу та актуальну для вас інформацію й вибрати зручний канал зв\'язку, просто увімкніть необхідну категорію сповіщень': 'Чтобы подписаться на важную и актуальную для вас информацию и выбрать удобный канал связи, просто включите необходимую категорию уведомлений',
            }
        }
    }
}
